@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-path-1 {
    clip-path: url(../public/assets/svg/clip-path-1.svg#svgPath);
  }
}

@font-face {
  font-family: "Righteous";
  src: local("Righteous"), url("https://fonts.googleapis.com/css2?family=Righteous&display=swap") format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap") format("truetype");
}

* {
  font-family: "Rubik", sans-serif;
  scrollbar-width: thin;
}

html,
body,
#app,
#root,
#app > div {
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}
.title {
  font-family: "Righteous";
}

body {
  background-color: rgb(250 250 249);
}
